<template>
  <div>
    <div class="row">
      <div class="col-xl-8">
        <Sessions></Sessions>
      </div>
      <div class="col-xl-4">
        <Free></Free>
      </div>
    </div>
  </div>
</template>

<script>
import Free from "@/view/content/widgets/engage/Free.vue";
import Sessions from "@/view/content/widgets/list/Sessions.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  name: "banners",
  components: {
    Free,
    Sessions
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Banners" }]);
  }
};
</script>
